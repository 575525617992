<template>
    <div>
        <div v-if="authUserPermission['mcr-draft-list-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('draft_list') }}</h3>
                            </div>
                            <div class="text-right mr-3">
                                <base-button size="sm" type="default" @click="filter">{{ tt('filter') }}</base-button>
                                <base-button size="sm" type="default" @click="ShowImportMcrItem()">{{
            tt("import_mcr_item") }}</base-button>
                                <base-button size="sm" type="default" @click="create">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table height="500px" class="table-responsive table-flush"
                            header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('type')" :prop="tt('type')" min-width="152px" sortable>
                                <template v-slot="{ row }">
                                    {{ row.form_description }}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('subject')" :prop="tt('subject')" min-width="150px" sortable>
                                <template v-slot="{ row }">
                                    {{ row.subject }}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('created_at')" :prop="tt('created_at')" min-width="171px"
                                sortable>
                                <template v-slot="{ row }">
                                    {{ format_date(row.created_at) }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="100">
                                <template v-slot="{ row }">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction"
                                        :hide-on-click="true">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show"
                                            slot="dropdown">
                                            <router-link
                                                :to="'/material/mcr-form/' + row.form_type + '/' + row.mcr_code + '/' + row.mcr_item_code + '/' + row.token + '/edit'"
                                                class="dropdown-item ml-1" tag="button">{{ tt('edit') }}</router-link>
                                            <el-dropdown-item :command="{ action: 'remove', data: row }">{{ tt('delete')
                                                }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                        <skeleton-loading v-else />
                    </div>
                    <div class="card-footer pb-0 ">
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page"
                                @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>
                        <span class="float-right">
                            {{ tt('page_info', {
            size: (table.page.current_page - 1) * table.page.per_page +
                table.data.length, total:
                table.total
        }) }}
                        </span>
                    </div>
                </div>
            </div>
            <validation-observer v-slot="{ invalid }">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ form.title }}</h5>
                    </template>
                    <div>
                        <label class="form-control-label">{{ tt('subject') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('subject')" :placeholder="tt('mcr_subject')" v-model="draftList.subject"
                            rules="required"></base-input>

                        <label class="form-control-label">{{ tt('type') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('type')" rules="required">
                            <el-select class="select-danger" v-model="draftList.type" :placeholder="tt('choose_type')"
                                filterable>
                                <el-option class="select-danger" :value="mf['form_code']"
                                    :label="(authUserRole['Administrator']) ? mf['form_description'] : mf['form_description'].split('(')[0]"
                                    :key="mf['form_code']" v-for="mf in masterForm"></el-option>
                            </el-select>
                        </base-input>

                        <label v-if="['MCR20', 'MCR21', 'MCR22', 'MCR23'].includes(draftList.type)"
                            class="form-control-label">{{
                            tt("mcr_type") }}<span class="text-danger">*</span></label>
                        <base-input v-if="['MCR20', 'MCR21', 'MCR22', 'MCR23'].includes(draftList.type)"
                            :name="tt('mcr_type')"
                            :rules="['MCR20', 'MCR21', 'MCR22', 'MCR23'].includes(draftList.type) ? 'required' : ''">
                            <el-select class="select-danger" v-model="draftList.mcr_type"
                                :placeholder="tt('choose_mcr_type')">
                                <el-option class="select-danger" value="consumable" label="Consumable"></el-option>
                                <el-option class="select-danger" value="suku_cadang_penyangga"
                                    label="SCP (Suku Cadang Penyangga)"></el-option>
                                <el-option class="select-danger" value="not_applicable"
                                    label="N/A (Not Applicable)"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait')
                                }}</span>
                            <span v-else>
                                {{ tt('add') }}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <modal :show.sync="formFilter.show">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('filter') }}</h5>
                </template>
                <div>
                    <label class="form-control-label">{{ tt('type') }}</label>
                    <base-input :name="tt('type')">
                        <el-select class="select-danger" v-model="table.search.type" :placeholder="tt('choose_type')"
                            filterable clearable>
                            <el-option class="select-danger" :value="mf['form_code']"
                                :label="mf['form_code'] + ' - ' + mf['form_description']"
                                :key="mf['form_code'] + ' - ' + mf['form_description']"
                                v-for="mf in  masterForm"></el-option>
                        </el-select>
                    </base-input>

                    <label class="form-control-label">{{ tt('subject') }}</label>
                    <base-input :name="tt('subject')" :placeholder="tt('subject')"
                        v-model="table.search.subject"></base-input>

                    <label class="form-control-label">{{ tt('created_at') }}</label>
                    <base-input type="date" :name="tt('created_at')" :placeholder="tt('created_at')"
                        v-model="table.search.created_at" />
                </div>
                <template slot="footer">
                    <base-button type="primary" v-on:click="filtering" :disabled="btnFilter.onLoading">
                        <span v-if="btnFilter.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait')
                            }}</span>
                        <span v-else>
                            {{ tt('filter') }}
                        </span>
                    </base-button>
                </template>
            </modal>

            <!-- Import MCR Item Modal -->

            <modal :show.sync="importMcrItemModal.show" size='lg'>
                <template slot="header">
                    <h5 class="modal-title">{{ tt('import_mcr_item') }}</h5>
                </template>
                <label class="form-control-label">{{ tt('type') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('type')" rules="required">
                    <el-select class="select-danger" v-model="draftList.type" :placeholder="tt('choose_type')"
                        filterable>
                        <el-option class="select-danger" :value="mf['form_code']"
                            :label="mf['form_code'] + ' - ' + mf['form_description']"
                            :key="mf['form_code'] + ' - ' + mf['form_description']"
                            v-for="mf in masterForm"></el-option>
                    </el-select>
                </base-input>
                <label class="form-control-label">Import File</label>
                <file-input @click="setNullError" @change="filesChange" :ref="'attachmentfile'"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    :initial-label="maxValidation.text" :max-file="maxValidation.size"></file-input>

                <div class="row">
                    <div class="col-md-6">
                        <label class="form-control-label mb-1 float-left mt-2" style="cursor:pointer"
                            @click="showDownloadTemplate"><span style="font-size:12px">Download Template? <i
                                    class="fa fa-caret-down"></i></span></label>
                        <select class="form-control form-control-sm float-left mb-2" v-model="downloadTemplateMcrItem"
                            @change="downloadTemplate" v-if="downloadTemplateMcrItemShow">
                            <option value="">Choose Template</option>
                            <option v-for="tmi in templateMcrItem" :value="tmi">{{ tmi }}</option>
                        </select>
                    </div>
                </div>

                <hr>
                <div v-if="tableError.show === true">
                    <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light"
                        :data="tableError.error">
                        <el-table-column :label="tt('row')" :prop="tt('row')" sortable min-width="75">
                            <template v-slot="{ row }">
                                {{ row.row }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('column')" :prop="tt('column')" min-width="90" sortable>
                            <template v-slot="{ row }">
                                {{ parseInt(row.attribute) + 1 }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('message')" :prop="tt('message')" min-width="280" sortable>
                            <template v-slot="{ row }">
                                {{ row.errors[0] }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('values')" :prop="tt('values')" min-width="100" sortable>
                            <template v-slot="{ row }">
                                {{ row.values[row.attribute] }}
                            </template>
                        </el-table-column>
                    </el-table><br>
                    Total Error : {{ tableError.error.length }} <br>
                    Total Success : {{ tableError.success }} Rows
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="importMcrItemModal.show = false">{{ tt('close')
                        }}</base-button>
                    <base-button type="primary" v-on:click="ImportMcrItem" :disabled="btnSave.onLoading || btnAdd">
                        <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait')
                            }}</span>
                        <span v-else>
                            <span>{{ tt('add') }}</span>
                        </span>
                    </base-button>
                </template>
            </modal>
        </div>
        <noaccess v-else />
    </div>
</template>
<script>
import { mapState } from 'vuex'
import Api from '@/helpers/api';
import draftList from '@/services/material/draftList.service';
import form from '@/services/setting/form.service';
import baseApiUrl from '@/configs/config';

var moment = require('moment');

export default {
    data() {
        return {
            moment: moment,
            onLoad: true,
            loadTimeout: null,
            btnSave: {
                onLoading: false
            },
            btnFilter: {
                onLoading: false
            },
            form: {
                title: this.tt('add_new_mcr'),
                show: false
            },
            formFilter: {
                show: false
            },
            table: {
                search: {
                    type: this.$route.query.type ? this.$route.query.type : '',
                    subject: this.$route.query.subject ? this.$route.query.subject : '',
                    created_at: this.$route.query.created_at ? this.$route.query.created_at : ''
                },
                total: 0,
                data: [],
                page: [],
            },
            errorMessage: null,
            draftList: {},
            catalogType: {},
            masterForm: {},
            importMcrItemModal: {
                show: false,
                data: []
            },
            downloadTemplateMcrItem: '',
            downloadTemplateMcrItemShow: false,
            templateMcrItem: [
                'A1_MCR_Form_Create_New_User.xlsx',
                'A2_MCR_Form_Change_Client_User.xlsx',
                'A2_MCR_Form_Change_Plant_User.xlsx',
                'A3_MCR_Form_Extend_User.xlsx',
                'A4_MCR_Form_Deletion_User.xlsx',
                'B5_MCR_Form_Konsolidasi.xlsx',
            ],
            attachmentDocument: {
                attachment: [],
            },
            btnAdd: true,
            mcrType: '',
            maxValidation: {
                size: null,
                text: null
            },
            tokenUrl: localStorage.token,
            apiUrl: baseApiUrl.apiUrl,
            tableError: {
                error: [],
                success: '',
                show: false,
            },
        }
    },
    computed: {
        ...mapState({
            authUserRole: state => state.auth.userRole,
            authUserPermission: state => state.auth.userPermission
        }),
    },
    mounted() {
        this.maximumValidation();
        this.get()
        this.getForm()
    },
    methods: {
        maximumValidation() {
            this.maximumSizeAlert().then((res) => {
                this.maxValidation.size = res.size
                this.maxValidation.text = res.text
            })
        },
        get() {
            let context = this;
            Api(context, draftList.getDraftList(this.table.page.current_page, { type: context.table.search.type, subject: context.table.search.subject, created_at: context.table.search.created_at })).onSuccess(function (response) {
                context.table.total = response.data.data.data.total;
                context.table.data = response.data.data.data.data;
                context.table.page = response.data.data.data;
                context.onLoad = false;
                contxet.draftList = {}
            }).onError(function (error) {
                if (error.response.status == 404) {
                    context.table.data = [];
                    context.table.total = 0;
                    context.onLoad = false;
                }
            })
                .call()
        },
        getForm() {
            let context = this;
            Api(context, form.getByRole()).onSuccess(function (response) {
                context.masterForm = response.data.data;
            }).onError(function (error) {
                if (error.response.status == 404) {
                    context.masterForm = [];
                }
            })
                .call()
        },
        filter() {
            this.formFilter.show = true
        },
        filtering() {
            this.$router.replace({ 'query': null })
            let context = this;
            context.onLoad = true;
            context.btnFilter.onLoading = true;
            Api(context, draftList.getDraftList('none', { type: context.table.search.type, subject: context.table.search.subject, created_at: context.table.search.created_at })).onSuccess(function (response) {
                context.table.data = response.data.data.data.data;
                context.table.total = response.data.data.data.total;
            }).onError(function (error) {
                if (error.response.status == 404) {
                    context.table.data = [];
                }
            }).onFinish(function () {
                context.onLoad = false;
                context.btnFilter.onLoading = false;
                context.formFilter.show = false;
                context.$router.replace({ 'query': { type: context.table.search.type, subject: context.table.search.subject, created_at: context.table.search.created_at } })
            }).call()
        },
        handleTableAction(command) {
            switch (command.action) {
                case 'edit':
                    this.edit(command.data);
                    break;
                case 'remove':
                    this.remove(command.data);
                    break;
                default:
                    break;
            }
        },
        create() {
            this.errorMessage = null;
            this.form.add = true;
            this.form.title = this.tt('add_new_mcr');
            this.form.show = true;
            this.draftList = {};
        },
        edit(id) {
            this.errorMessage = null;
            let context = this;
            Api(context, draftList.show(id)).onSuccess(function (response) {
                context.draftList = response.data.data;
                context.form.add = false;
                context.form.title = context.tt('edit_new_mcr');
                context.form.show = true;
            })
                .call()
        },
        save() {
            this.btnSave.onLoading = true;
            let api = null;
            let context = this;

            if (this.form.add) {
                api = Api(context, draftList.create(this.draftList));
            } else {
                {
                    api = Api(context, draftList.update(this.draftList.id, this.draftList));
                }
            }

            api.onSuccess(function (response) {
                context.get();
                context.form.show = false;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    context.$router.push({ path: '/material/mcr-form/' + response.data.data.form_type + '/' + response.data.data.mcr_code + '/' + response.data.data.mcr_item_code + '/' + response.data.data.token + '/edit' });
                }, 100);
            }).onError(function (error) {
                context.errorMessage = error.response.data;
            }).onFinish(function () {
                context.btnSave.onLoading = false;
            })
                .call();
        },
        remove(params) {
            let context = this;
            context.confirmDialog(context.tt('confirm_delete')).then((result) => {
                if (result.value) {
                    context.onLoad = true;
                    Api(context, draftList.deleteMcr(params.mcr_code, params.token)).onSuccess(function (response) {
                        context.$notify({
                            message: response.data.message,
                            type: 'success',
                            verticalAlign: 'bottom',
                            horizontalAlign: 'left'
                        });
                        context.get();
                        context.onLoad = false;
                    }).call();
                }
            })
        },
        changePage(page) {
            let context = this;
            context.onLoad = true;

            clearTimeout(this.loadTimeout);
            this.loadTimeout = setTimeout(() => {
                this.get()
            }, 10);
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).locale('id').format('LLL')
            }
        },
        ShowImportMcrItem() {
            this.importMcrItemModal.show = true;
        },
        ImportMcrItem() {
            this.btnSave.onLoading = true;
            let api = null;
            let context = this;
            let formData = new FormData();

            formData.append('select_file', this.attachmentDocument.attachment);

            api = Api(context, draftList.import_mcr_item(context.draftList.type, formData));
            api.onSuccess(function (response) {
                context.$notify({
                    message: response.data.message,
                    type: 'success'
                });
                context.importMcrItemModal.show = false;
                context.tableError.show = false;
            }).onError(function (error) {
                context.$notify({
                    message: error.response.data.message,
                    type: 'danger',
                    verticalAlign: 'bottom',
                    horizontalAlign: 'left'
                });
                context.tableError.error = error.response.data.data.failures;
                context.tableError.success = error.response.data.data.success;
                context.tableError.show = true;
                context.errorMessage = error.response.data;
                context.btnSave.onLoading = false;
            }).onFinish(function () {
                context.get();
                context.btnSave.onLoading = false;
                context.btnAdd = true;
                context.$refs.attachmentfile.files = '';
                context.$refs.attachmentfile.fileChange(null);
            })
                .call();
        },
        downloadTemplate() {
            if (this.downloadTemplateMcrItem != '') {
                location.href = this.apiUrl + 'emcr/download-template-mcr-item/' + this.downloadTemplateMcrItem + '?token=' + this.tokenUrl;
            }
            this.downloadTemplateMcrItem = ''
            this.showDownloadTemplate()
        },
        setNullError() {
            this.table.error = [];
            this.table.success = 0;
        },
        filesChange(files) {
            if (files.length === 0) {
                return;
            }

            if (files[0].size > this.maxValidation.size) {
                this.attachmentDocument.attachment = undefined;
                this.$notify({
                    message: this.maxValidation.text,
                    type: 'danger',
                    verticalAlign: 'bottom',
                    horizontalAlign: 'left'
                });

                this.btnAdd = true
            } else {
                this.attachmentDocument.attachment = files[0];
                this.btnAdd = false
            }
        },
        showDownloadTemplate() {
            this.downloadTemplateMcrItemShow = !this.downloadTemplateMcrItemShow
        }
    }
};
</script>
<style></style>
